<script>
// https://www.npmjs.com/package/vue-image-lightbox
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'
import Lightbox from 'vue-image-lightbox'

export default {
    name: 'e-fancybox',
    extends: Lightbox
}
</script>
